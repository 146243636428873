import * as React from "react";

function SvgComponent(props) {
  return (
    <svg id="pictogram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 313.67 393.94">
  <path class="cls-1" d="m225.39,110.29c-1.49-9.28-6.26-17.74-13.43-23.8-7.1-6-16.1-9.3-25.34-9.3-2.08,0-4.19.17-6.26.5-9.8,1.57-20.37,9.2-28.27,20.42-8.25,11.72-12.04,24.86-10.11,35.16,1.63,8.71,6.04,15.79,12.76,20.48,6.1,4.26,13.94,6.5,22.67,6.5h0c4.18,0,8.46-.52,12.71-1.55,1.05-.25,2.07-.56,3.09-.89l-.24-.57c-.99.32-1.99.61-3,.86-4.2,1.01-8.43,1.53-12.56,1.53h0c-18.71,0-31.73-9.9-34.83-26.48-1.89-10.14,1.85-23.11,10.01-34.69,7.81-11.08,18.22-18.62,27.86-20.16,2.04-.33,4.12-.49,6.17-.49,19.09,0,35.13,13.7,38.16,32.58,2.52,15.72-6.82,32.73-21.38,41.96l.24.57c14.8-9.35,24.31-26.64,21.75-42.63Z"/>
  <path class="cls-1" d="m221.26,110.95c-1.33-8.3-5.59-15.85-12-21.27-6.34-5.36-14.38-8.31-22.64-8.31-1.86,0-3.74.15-5.6.45-9.01,1.44-17.85,7.81-24.25,17.47-1.64,2.48-3.02,5.09-4.17,7.74l.61.15c1.13-2.58,2.47-5.12,4.08-7.54,6.3-9.51,14.98-15.78,23.83-17.2,1.82-.29,3.67-.44,5.5-.44,17.03,0,31.34,12.22,34.04,29.06,3,18.75-11.53,38.59-30.48,41.63-2.53.4-5.07.61-7.57.61h0c-17.52,0-30.39-9.65-32.78-24.59-.53-3.3-.47-6.85.1-10.49l-.6-.14c-.59,3.72-.65,7.35-.11,10.73,2.44,15.25,15.55,25.11,33.39,25.11h0c2.53,0,5.11-.21,7.66-.62,19.28-3.09,34.05-23.27,31-42.33Z"/>
  <path class="cls-1" d="m217.58,111.54c-2.45-15.32-15.48-26.44-30.96-26.44-1.66,0-3.35.13-5,.4-7.41,1.19-14.19,5.65-19.09,12.58-5.74,8.12-8.13,18.64-6.39,28.16.67,3.69,1.86,7.03,3.46,9.96l.44-.37c-1.52-2.85-2.64-6.09-3.3-9.7-1.71-9.36.64-19.71,6.29-27.7,4.8-6.79,11.44-11.17,18.68-12.33,1.62-.26,3.27-.39,4.91-.39,15.18,0,27.95,10.9,30.36,25.92,1.28,7.98-1.05,16.71-6.39,23.96-5.32,7.23-12.98,12.11-21,13.39-1.98.32-3.94.48-5.82.48h0c-6.32,0-11.82-1.72-16.22-4.91l-.41.35c4.54,3.34,10.21,5.18,16.63,5.18h0c1.92,0,3.91-.16,5.92-.49,8.18-1.31,15.98-6.28,21.4-13.63,5.44-7.38,7.81-16.28,6.5-24.42Z"/>
  <path class="cls-1" d="m214.29,112.07c-2.19-13.69-13.83-23.63-27.68-23.63-1.49,0-2.99.12-4.47.36-5.51.88-9.7,3.38-12.76,6.82l.6.26c2.97-3.26,7-5.62,12.26-6.47,1.45-.23,2.92-.35,4.37-.35,13.54,0,24.93,9.72,27.07,23.11,1.14,7.11-.97,14.94-5.8,21.48-4.81,6.51-11.67,10.91-18.83,12.05-1.34.21-2.65.32-3.91.32h0c-11.09,0-18.87-8.8-21.32-24.13-.84-5.25-.66-10.81.73-15.84l-.57-.25c-1.43,5.14-1.63,10.82-.77,16.18,2.51,15.66,10.5,24.65,21.93,24.65h0c1.29,0,2.63-.11,4-.33,7.32-1.17,14.33-5.65,19.23-12.3,4.92-6.67,7.08-14.67,5.91-21.94Z"/>
  <path class="cls-1" d="m208.85,126.65c-.67,1.46-1.48,2.88-2.44,4.21-3.83,5.3-9.5,8.78-15.96,9.82-1.41.23-2.72.34-3.91.34-9.96,0-12.07-8.16-15.01-19.46-2.56-9.86-2.14-18.09,1.18-23.17,2.15-3.3,5.52-5.33,10-6.05,1.29-.21,2.6-.31,3.9-.31,12.08,0,22.23,8.67,24.14,20.61.14.9.23,1.81.27,2.71l.62.1c-.04-.97-.13-1.94-.29-2.91-1.96-12.24-12.37-21.13-24.75-21.13-1.33,0-2.67.11-4,.32-4.66.75-8.16,2.87-10.42,6.32-3.42,5.23-3.87,13.63-1.26,23.66,3,11.56,5.17,19.92,15.6,19.92,1.22,0,2.57-.12,4.01-.35,6.62-1.06,12.43-4.63,16.36-10.06,1.02-1.41,1.87-2.92,2.57-4.47l-.63-.1Z"/>
  <path class="cls-1" d="m228.65,119.75c-1.1,7.7-4.6,15.7-10.27,23.05-8.1,10.5-19.53,18.34-30.57,20.99-4.48,1.07-9.01,1.62-13.47,1.62h0c-4.73,0-9.18-.61-13.28-1.77-14.08-3.89-22.82-14.13-26.36-26.1-3.04-10.28-.78-23.03,6.2-34.97,8.16-13.96,21.3-24.19,35.15-27.37,3.35-.77,6.76-1.16,10.14-1.16,20.42,0,38.16,14.39,42.2,34.21h.63s0-.05-.01-.07c-2-9.86-7.4-18.76-15.21-25.06-7.76-6.26-17.56-9.7-27.6-9.7-3.42,0-6.88.4-10.28,1.18-14.02,3.22-27.31,13.56-35.55,27.66-7.06,12.09-9.35,25.01-6.26,35.46,3.6,12.18,12.31,22.41,26.73,26.51,4.17,1.18,8.69,1.8,13.49,1.8h0c4.5,0,9.08-.55,13.61-1.63,11.18-2.67,22.74-10.6,30.92-21.21,5.76-7.46,9.31-15.59,10.4-23.43h-.62Z"/>
</svg>
  );
}

export default SvgComponent;
