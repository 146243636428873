import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/fegimi.png";
import ProjectImg2 from "../../assets/img/projects/tetra.png";
import ProjectImg3 from "../../assets/img/projects/fora.png";
import ProjectImg4 from "../../assets/img/projects/variety.png";
import ProjectImg5 from "../../assets/img/projects/arriva.png";
import ProjectImg6 from "../../assets/img/projects/solful.png";
import TeamPhoto from "../../assets/img/ktkAbout.png"

export default function Projects() {
  return (
    <Wrapper id="projects" style={{marginTop:"50px"}}>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
            Our team has had the pleasure of working on some truly awesome projects for international clients in web and mobile application development. We've delivered high-quality, custom solutions that have exceeded our clients' expectations and helped their businesses thrive. Our expertise in both web and mobile applications ensures that we can provide a complete end-to-end solution for your business.

            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                link="https://www.fegime.com/"
                img={ProjectImg1}
                title="Fegime"
                text="The solution enables direct client-associate connection for improved sales incentives and benefits for clients, sales force, and sponsor suppliers."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                link="https://www.teatromassimo.it/en/"
                img={ProjectImg2}
                title="Teatromassimo"
                text="Teatromassimo is a theater app that allows users to view upcoming events, provides information about artists, and offers exclusive content related to the events."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                link="https://www.fora.it/"
                img={ProjectImg3}
                title="Fora"
                text="FORA is a web-based application that supports various workflows in a radiopharmaceutical laboratory, including equipment management, patient scheduling, inventory control, user access, and administrative tasks."
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                link="https://www.varietydistribution.it/en/"                
                img={ProjectImg4}
                title="Variety"
                text="Variety is an e-commerce platform that employs advanced image recognition technology to facilitate the purchase of movies and connect individuals who share an interest in film-related events."              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                link="https://arriva.it/"
                img={ProjectImg5}
                title="Arriva"
                text="Arriva is a European transportation company that has created user-friendly platforms for ticketing, subscriptions, and user management. These platforms include native iOS and Android applications, as well as a multisite website."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                link="https://solful.io/"
                img={ProjectImg6}
                title="Solful Network"
                text="Solful is a cross-chain networking website for Web 3.0 environment featuring personal profiles and resumes with job boards and workflow management tools."
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={TeamPhoto} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold">About Us</h2>
              <p className="font12">
              We recognize the significance of being up-to-date with technology at our organization. Therefore, we provide a comprehensive range of IT services, including ERP solutions, mobile application development, data-driven applications, custom software development, and cloud solutions. Our team of highly-skilled professionals is dedicated to keeping up with the latest technological advancements and identifying innovative approaches to support the success of your business.

              </p>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
